import { ImageRatio } from '@components/ImageThumbnailRatio/ImageRatio';
import VnrLink from '@components/VnrLink';
import dynamic from 'next/dynamic';
import React from 'react';

import styles from './Menu.module.scss';

const MenuDesktop = dynamic(() => import('./MenuDesktop'), {
  ssr: false
});
const MenuMobile = dynamic(() => import('./MenuMobile'), {
  ssr: false
});

const Menu = ({ featurePostGroups, translate }) => {
  
  return (
    <div id="menuVnrebates" className={styles.Menu}>
      <div className={styles.logo}>
        <VnrLink to="/">
          <ImageRatio
            src={translate.home.logo}
            alt="vnRebates"
            ratio="1500:406.4"
          />
        </VnrLink>
      </div>
      <div className={styles.desktopMenu}>
        <MenuDesktop 
          featurePostGroups={featurePostGroups} 
          translate={translate}
        />
      </div>
      <div className={styles.mobileMenu}>
        <MenuMobile translate={translate} />
      </div>  
    </div>
  )
}
export default Menu;